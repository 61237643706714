// CommandList.js

import React from 'react';

function CommandList({ data, selectedLanguage, setSelectedCommand }) {
  const commands = selectedLanguage ? data[selectedLanguage].sort() : [];

  return (
    <div className="command-list">
      <h2>Command List</h2>
      <ul>
        {commands.map(command => (
          <li key={command} onClick={() => setSelectedCommand(command)}>
            {command}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CommandList;
