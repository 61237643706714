// LanguageList.js
import React from 'react';

function LanguageList({ setSelectedLanguage, selectedLanguage }) {
  const languages = ["JavaScript", "Python", "Java"]; // exemple de liste de langages

  return (
    <div className="language-list">
      {languages.map(language => (
        <button
          key={language}
          className={selectedLanguage === language ? 'selected' : ''}
          onClick={() => setSelectedLanguage(language)}
        >
          {language}
        </button>
      ))}
    </div>
  );
}

export default LanguageList;
