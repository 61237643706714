// Page3d.js

import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Page3d.css';

function Page3d() {
  const containerRef = useRef();
  const rendererRef = useRef(); // Ajoutez une référence pour le renderer

  useEffect(() => {
    console.log('Initialisation de la scène...');
    const scene = new THREE.Scene();
    
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.set(0, 0, 10);
    
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(500, 500);
    rendererRef.current = renderer; // Stockez le renderer dans la référence
    containerRef.current.appendChild(renderer.domElement);
    
    const loader = new STLLoader();
    let mesh;
    
    loader.load(
      './components/medias/stl/logo.stl',
      (geometry) => {
        console.log('Modèle STL chargé avec succès.');
        const material = new THREE.MeshNormalMaterial();
        mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(0, 0, 0);
        scene.add(mesh);
      },
      undefined,
      (error) => {
        console.error('Erreur lors du chargement du fichier STL:', error);
      }
    );

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(1, 1, 1).normalize();
    scene.add(light);

    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const animate = () => {
      requestAnimationFrame(animate);
      if (mesh) {
        mesh.rotation.x += 0.01;
        mesh.rotation.y += 0.01;
      }
      rendererRef.current.render(scene, camera); // Utilisez le renderer stocké
    };
    animate();

    return () => {
      console.log('Nettoyage du renderer...');
      if (containerRef.current && rendererRef.current) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="containerPage3d">
        <div className="sceneContainer" ref={containerRef}></div>
      </div>
      <Footer />
    </div>
  );
}

export default Page3d;
