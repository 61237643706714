// NewPage.js

import React from 'react';
import Header from '../Header/Header'; // Importez le composant Header
import Footer from '../Footer/Footer'; // Importez le composant Footer

function NewPage() {
  return (
    <div>
      <Header /> {/* Affiche le Header */}
      <div>
        <h1>Nouvelle Page</h1>
        <p>Contenu de la nouvelle page...</p>
      </div>
      <Footer /> {/* Affiche le Footer */}
    </div>
  );
}

export default NewPage;
