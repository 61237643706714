import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMouseEnter = () => {
    console.log('Mouse entered header1');
    setMenuVisible(true);
  };

  const handleMouseLeave = () => {
    console.log('Mouse left header1');
    setMenuVisible(false);
  };

  return (
    <header className="header">
      <div className="header1"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        |
        {menuVisible && (
          <div className="menu1 headerMenu1">
            <div className="menu1Titre headerMenu1">
              Thème
            </div>
            <div className="menu1Selecteur headerMenu1">
              Ørigine<br />
            </div>
            <div className="menu1Selecteur headerMenu1">
              Constrasté .x.<br />
            </div>
            <div className="menu1Selecteur headerMenu1">
              Psyché .x.<br />
            </div>
          </div>
        )}
      </div>
  
      <div className="header2">
        |
      </div>
  
      <div className="header3">
        <a href="/">-3xØc3t-</a>
      </div>
  
      <div className="header4">
        |
      </div>
  
      <div className="header5">
        |
      </div>
    </header>
  );
}

export default Header;
