// Cont4ct.js //

import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import axios from 'axios';
import './Cont4ct.css';

function Cont4ct() {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [objet, setObjet] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');

    try {
      const response = await axios.post('/send-email', {
        nom,
        prenom,
        email,
        telephone,
        objet,
        message
      }, {
        auth: {
          username: process.env.EMAIL_USER,
          password: process.env.EMAIL_PASSWORD
        }
      });
      console.log('Response:', response.data);

      // Réinitialisation des champs après l'envoi
      setNom('');
      setPrenom('');
      setEmail('');
      setTelephone('');
      setObjet('');
      setMessage('');

      alert('Votre message a été envoyé avec succès!');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email :', error);
      alert('Une erreur s\'est produite lors de l\'envoi de votre message. Veuillez réessayer plus tard.');
    }
  };

  return (
    <div>
      <Header />
      <div className="containerCont4ct">
        <form className="cont4ct-form" onSubmit={handleSubmit}>
          <div className="cont4ct-form-Title">
            Cont4ct
          </div>

          <label htmlFor="nom">Nom :</label>
          <input type="text" id="nom" value={nom} onChange={(e) => setNom(e.target.value)} required />

          <label htmlFor="prenom">Prénom :</label>
          <input type="text" id="prenom" value={prenom} onChange={(e) => setPrenom(e.target.value)} required />

          <label htmlFor="email">Adresse mail :</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

          <label htmlFor="telephone">Numéro de téléphone : *</label>
          <input type="tel" id="telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} />

          <label htmlFor="objet">Objet :</label>
          <input type="text" id="objet" value={objet} onChange={(e) => setObjet(e.target.value)} required />

          <label htmlFor="message">Message :</label>
          <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />

          (* Facultatif)

          <button type="submit">Envoyer</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Cont4ct;
