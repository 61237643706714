// ContentBlock.js

import React from "react";
import { Link } from "react-router-dom"; // Importez le composant Link
import "./ContentBlock.css";

function ContentBlock() {
  return (
    <div className="container">
      

      <div className="bloc">
        <div className="bloc1">
          -W3B-
          <div className="menu menu1">
            <Link to="/C0deLibrary">C0deLibrary</Link>
            <br />
            <Link to="/">Applications</Link>
            <br />
            <Link to="/newPage">Nouvelle Page</Link>
            <br />
          </div>
        </div>

        <div className="bloc2">-H4cks-
          <div className="menu menu2">
            <Link to="/">Projet M4dMax Bike</Link>
            <br />
            <Link to="/">IOT</Link>
            <br />
            <Link to="/">Autres</Link>
            <br />
          </div>
        </div>

        <div className="bloc3">-4RT-
          <div className="menu menu3">
            <Link to="/">GALERIE</Link>
            <br />
            <Link to="/">CØde_4rt</Link>
            <br />
            <Link to="/Page3d">3d</Link>
            <br />
          </div>
        </div>

        <div className="bloc4">-SH0P-
          <div className="menu menu4">
            <Link to="/">BOUTIQUE</Link>
            <br />
            <Link to="/">DEVIS</Link>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentBlock;
