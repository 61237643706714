/* InfoBlock.js */

import React from 'react';
import './InfoBlock.css';

function InfoBlock() {
  return (
    <div className="infoBlockMain">
      <div className="infoBlockTitle">
        InfOS :
      </div>
      <a href="/" className="infoItem">
        <span className="boldText">20240506-00:26</span> - <span className="normalText">implémentation de C0dePaint...</span>
      </a> <br />
      <a href="/" className="infoItem">
        <span className="boldText">20240302-13:12</span> - <span className="normalText">REACT UPGRADE</span>
      </a> <br />
      <a href="/" className="infoItem">
        <span className="boldText">20240225-04:45</span> - <span className="normalText">màJ Template</span>
      </a> <br />
    </div>
  );
}

export default InfoBlock;
