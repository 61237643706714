// C0deMemo.js

import React, { useState } from 'react';
import LanguageList from './LanguageList';
import CommandList from './CommandList';
import CommandDetails from './CommandDetails';
import data from './data'; // importez vos données de commandes ici

function C0deMemo() {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCommand, setSelectedCommand] = useState(null);

  return (
    <div className="app">
      <LanguageList
        setSelectedLanguage={setSelectedLanguage}
        selectedLanguage={selectedLanguage}
      />
      <CommandList
        data={data} // Ajoutez la prop 'data'
        selectedLanguage={selectedLanguage}
        setSelectedCommand={setSelectedCommand}
      />
      <CommandDetails
        selectedCommand={selectedCommand}
        commands={selectedLanguage && data[selectedLanguage]} // Ajoutez la vérification pour 'selectedLanguage'
      />
    </div>
  );
}

export default C0deMemo;
