// CommandDetails.js

import React from 'react';

function CommandDetails({ selectedCommand, commands }) {
  const commandData = selectedCommand && commands ? commands[selectedCommand] : null;

  return (
    <div className="command-details">
      {selectedCommand && commandData ? (
        <>
          <h2>{selectedCommand}</h2>
          <p>Description: {commandData.description}</p>
          <p>Usage: {commandData.usage}</p>
          <p>Context: {commandData.context}</p>
        </>
      ) : (
        <p>Select a command to see details</p>
      )}
    </div>
  );
}

export default CommandDetails;
