/* data.js */

const data = {
    javascript: [
      { command: 'console.log()', description: 'Utilisé pour afficher un message dans la console.' },
      // Ajoutez d'autres commandes JavaScript ici...
    ],
    python: [
      { command: 'print()', description: 'Utilisé pour afficher un message dans la console.' },
      // Ajoutez d'autres commandes Python ici...
    ],
    // Ajoutez d'autres langages et leurs commandes correspondantes ici...
  };
  
  export default data;
  