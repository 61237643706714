// App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header/Header';
import InfoBlock from './components/InfoBlock/InfoBlock';
import ContentBlock from './components/ContentBlock/ContentBlock';
import Footer from './components/Footer/Footer';
import C0deMemo from './components/C0deMemo/C0deMemo';
import C0deLibrary from './components/C0deLibrary/C0deLibrary';
import Page3d from './components/Page3d/Page3d';
import NewPage from './components/NewPage/NewPage';
import Cont4ct from './components/Cont4ct/Cont4ct';

import './Styles/App.css';

function App() {
  useEffect(() => {
    document.title = "3x0c3t.com";
  }, []);
  
  return (
    <Router>
      <div className="App">
        <Header />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/C0deMemo" element={<C0deMemo />} />
          <Route path="/NewPage" element={<NewPage />} />
          <Route path="/Cont4ct" element={<Cont4ct />} />
          <Route path="/C0deLibrary" element={<C0deLibrary />} />
          <Route path="/Page3d" element={<Page3d />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <InfoBlock />
      <ContentBlock />
    </div>
  );
}

export default App;
