// Footer.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import BubbleAnimation from "../BubbleAnimation/BubbleAnimation"; // chemin relatif vers le composant BubbleAnimation

import "./Footer.css";

function Footer() {
  const [showBubbles, setShowBubbles] = useState(false);

  const handleFooterClick = () => {
    setShowBubbles(true);
    setTimeout(() => {
      setShowBubbles(false);
    }, 2000); // Durée de l'animation en millisecondes
  };

  return (
    <footer className="footer">
      <div className="footer1">
        <Link to="/cont4ct">CONT4CT</Link>
      </div>
      <div className="footer2"></div>
      <div className="footer3">© 2024 -3xØc3t-</div>
      <div className="footer4"></div>
      <div className="footer5" onClick={handleFooterClick}></div>
      {showBubbles && <BubbleAnimation />}
    </footer>
  );
}

export default Footer;
