// C0deLibrary.js

import React from 'react';
import Header from '../Header/Header'; // Importez le composant Header
import Footer from '../Footer/Footer'; // Importez le composant Footer

import './C0deLibrary.css'; // Importez le fichier CSS pour la C0deLibrary

import githubIcon from '../medias/externalIcons/github.png'; // Chemin relatif vers l'icône GitHub
import codepenIcon from '../medias/externalIcons/codepen.png'; // Chemin relatif vers l'icône CodePen


function C0deLibrary() {
  return (
    <div>
      <Header /> {/* Affiche le Header */}
      <div className="containerC0deLibrary">
        <div className="C0deLibrary">
          <div className="C0deLibraryTitle">
            C0deLibrary
          </div>
          <div className="C0deLibraryText">
            <div className="externalIcons">
              Liens vers mes comptes :<br></br>
              GITHUB : <a href="https://github.com/3x0c3t" target="_blank" rel="noreferrer"><img src={githubIcon} alt="GitHub" /></a>
              CODEPEN : <a href="https://codepen.io/3x0c3t" target="_blank" rel="noreferrer"><img src={codepenIcon} alt="CodePen" /></a>
            </div>
            <div className="ligneSeparation">
            </div>
          </div>
        </div>
      </div>
      <Footer /> {/* Affiche le Footer */}
    </div>
  );
}

export default C0deLibrary;
