// BubbleAnimation.js

import React from "react";
import "./BubbleAnimation.css";

function BubbleAnimation() {
  const bubbles = [];

  // Position de départ pour toutes les bulles
  const startPosition = { bottom: "15px", right: "20px" };

  // Générer huit bulles avec des tailles aléatoires
  for (let i = 0; i < 8; i++) {
    const size = Math.floor(Math.random() * 20) + 5; // Taille aléatoire entre 5 et 25px
    const delay = Math.random() * 2; // Délai d'apparition aléatoire
    const duration = Math.random() * 3 + 1; // Durée de l'animation aléatoire entre 1 et 4s
    const style = {
      width: size + "px",
      height: size + "px",
      ...startPosition, // Utilisation de la position de départ commune
      marginLeft: -(size / 2) + "px", // Centrage horizontal
      animationDelay: `${delay}s`,
      animationDuration: `${duration}s`
    };
    bubbles.push(
      <div key={i} className="bubble" style={style}></div>
    );
  }

  return <div>{bubbles}</div>;
}

export default BubbleAnimation;
